import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useLocale } from "src/common/hooks"

import { downloadFile } from "src/common/downloadFile"
import { getNodesByLocale } from "src/common/getNodesByLocale"
import { useHeaderColor } from "src/common/hooks"
import Layout from "src/layout"

import Banner from "../Banner"
import RelatedPosts from "src/templates/BlogPost/components/RelatedPosts"

const ThankYouPage = ({ data, location, pageContext }) => {
  const locale = useLocale()
  const modal = pageContext.modals.find(
    ({ modalId, node_locale }) =>
      modalId === pageContext.modalId && node_locale === locale
  )
  const posts = getNodesByLocale(data.allContentfulBlogPosts.nodes, locale)

  const { inverse, isChangeColor } = useHeaderColor({
    defaultInverse: true,
    elem: { selector: "#content", inverse: false },
  })

  const headerColor = {
    primaryColor:
      "linear-gradient(255.03deg, #7785FF -0.91%, #6472EF 102.89%) #fff",
    secondaryColor: "#fff",
  }

  useEffect(() => {
    downloadFile({
      url: `https:${modal?.downloadAsset?.asset?.file?.url}`,
      name: modal?.downloadAsset?.asset?.file?.fileName,
    })
  }, [modal?.downloadAsset?.asset?.file])

  return (
    <Layout
      location={location}
      headerColor={headerColor}
      headerInverse={inverse}
      headerIsChangeColor={isChangeColor}
    >
      <Banner json={modal?.successBody?.json} isSuccessBody />
      <RelatedPosts title={modal?.postsHeading} posts={posts} />
    </Layout>
  )
}

export default ThankYouPage

export const query = graphql`
  query {
    allContentfulBlogPosts(limit: 3) {
      nodes {
        blogPostId
        bpIntroduction {
          json
        }
        heroImage {
          fixed(width: 600) {
            src
            srcSet
          }
          description
        }
        heroImageTitle
        readLength
        node_locale
      }
    }
  }
`
