export const downloadFile = ({ url, name }) =>
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const blobURL = URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = blobURL
      a.style = "display: none"
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
